<template>
    <v-container fluid no-gutters class="secondary-font">
        <!-- <filters/> -->
        <v-row justify="center" align="center">
            <v-col
                v-for="(item, index) in teams"
                :key="index"
                cols="12"
                md="3"
            >
                <v-card
                    v-if=" item.name !== 'PLAYER POOL'"
                    class="rounded-lg py-2 px-3"
                    :style="`background: linear-gradient(110deg, ${item.color ? item.color : 'orange'} 20%, white 20%);`"
                    min-height="100px"
                    hover
                    @click="$router.push({path:`Team/${item.id}`})"
                >
                    <v-row class="py-1" align="center">
                        <v-col cols="12" md="4">
                            <v-img
                                v-if="item.logo"
                                :src="`${assetsUrl}/${item.logo}`"
                                height="70px"
                                contain
                            />
                            <v-icon v-else style="font-size: 70px;" class="orange--text"> mdi-basketball </v-icon>
                        </v-col>
                        <v-col cols="12" md="8" :align="$vuetify.breakpoint.mobile ? 'center' : 'start'">
                            <span class="black--text font-weight-bold secondary-font">
                                {{ item.name }}
                            </span>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    // import Filters from '@/components/Filters'
    import teamService from '@/services/team'

    export default {
        // components: { Filters },
        data() {
            return {
                teams: [],
                page: 1,
                size: 1000,
                totalPages: 1,
                assetsUrl: process.env.VUE_APP_GOOGLE_STORAGE_API
            }
        },
        created() {
            this.fetchAllTeams()
        },
        methods: {
            fetchAllTeams() {
                teamService.getTeams(this.page, this.size).then(resp => {
                    this.teams = resp.data.content
                    this.totalPages = resp.data.totalPages
                })
            }
        }
    }
</script>

<style scoped>

.text-h6 {
    font-size: 1.1rem !important;
    letter-spacing: 0.0125em !important;
    line-height: 2rem;
}

.extra-padding {
    padding-left: 120px;
    padding-right: 120px;
}

.custom-divider {
    border-width: 1px;
    height: 70px;
}

.bg {
    position: absolute;
    background: url('../assets/dunk-bg-1.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: auto;
    min-height: 100%;
}
</style>
